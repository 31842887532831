<template>
  <div
    id="google_translate_element"
    v-html="content"
  />
</template>

<script>
  /* eslint-disable */
  export default {
    data: function () {
      return {
        content: 'ABCDEF',
      }
    },
    beforeMount () {
      const script = document.createElement('script')
      script.id = this.recaptchaScriptId
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      document.head.appendChild(script)
      window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'ja', includedLanguages: 'vi', autoDisplay: true, layout: google.translate.TranslateElement.InlineLayout.SIMPLE },
          'google_translate_element',
        )
      }
      this.content = localStorage.getItem('content')
    },
    mounted () {
    },
  }
</script>
